import React from "react";
import "./Footer.css";
import logo from "./logo.png";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useNavigate } from "react-router-dom";
import { Hidden } from "@mui/material";
const Footer = () => {
  const history = useNavigate();
  return (
    <>
      <div className="footerContainer">
        <Hidden smDown>
          <div>
            <img
              onClick={() => history("/")}
              src={logo}
              alt="logo"
              className="footerLogo"
            />
            <div className="footerFoo">
              Copyright © 2023 DOERDEVS - All Rights Reserved
            </div>
          </div>
        </Hidden>
        <Hidden smDown>
          <div>
            <h2 className="footertext">Contact Us</h2>
            <div className="footerDiv">
              <PhoneIcon />
              <p>0751-4729888</p>
            </div>
            <div className="footerDiv">
              <a href="mailto:info@doerdevs.com?subject = Feedback&body = Message">
                <EmailIcon htmlColor="white" />
              </a>
              <a href="mailto:info@doerdevs.com?subject = Feedback&body = Message">
                <p style={{ color: "#fff" }}>info@doerdevs.com</p>
              </a>
            </div>
          </div>
          <div>
            <h2 className="footertext">Follow Us</h2>
            <div style={{ display: "flex", gap: "16px" }}>
              <a href="https://www.linkedin.com/company/doer-devs">
                <LinkedInIcon htmlColor="white" />
              </a>
              <InstagramIcon htmlColor="white" />
              <TwitterIcon htmlColor="white" />
            </div>
          </div>
          <div>
            <h2 className="footertext">Company Info</h2>
            <p className="footerText1" onClick={() => history("/services")}>
              Services
            </p>
            <p onClick={() => history("/about_us")}>About Us</p>
            <p>Portfolio</p>
            <p>Training</p>
            <p>Career</p>
          </div>
        </Hidden>

        <Hidden smUp>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2 className="footertext">Contact Us</h2>
            <div className="footerDiv">
              <PhoneIcon />
              <p>0751-4729888</p>
            </div>
            <div className="footerDiv">
              <a href="mailto:info@doerdevs.com?subject = Feedback&body = Message">
                <EmailIcon htmlColor="white" />
              </a>
              <a href="mailto:info@doerdevs.com?subject = Feedback&body = Message">
                <p style={{ color: "#fff" }}>info@doerdevs.com</p>
              </a>
            </div>
          </div>
          <div className="footerContainerDiv">
            <div>
              <h2 className="footertext">Follow Us</h2>
              <div style={{ display: "flex", gap: "16px" }}>
                <a href="https://www.linkedin.com/company/doer-devs">
                  <LinkedInIcon htmlColor="white" />
                </a>
                <InstagramIcon htmlColor="white" />
                <TwitterIcon htmlColor="white" />
              </div>
            </div>
            <div>
              <h2 className="footertext">Company Info</h2>
              <p className="footerText1" onClick={() => history("/about_us")}>
                About Us
              </p>
              <p className="footerText1" onClick={() => history("/services")}>
                Services
              </p>
              <p className="footerText1">Portfolio</p>
              <p className="footerText1">Training</p>
              <p className="footerText1">Career</p>
            </div>
          </div>
        </Hidden>
        <Hidden smUp>
          <div>
            <div className="footerFoo">
              Copyright © 2023 DOERDEVS - All Rights Reserved
            </div>
          </div>
        </Hidden>
      </div>
    </>
  );
};

export default Footer;
