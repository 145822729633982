import React from "react";
import "./Sectors.css";

const Sectors = () => {
  return (
    <>
      <div className="sectorsContainer">
        <div>
          <div>Health Sector</div>
          <div>Retail Sector</div>
          <div>Real Estate Sector</div>
        </div>
        <div>
          <div>Fintech Sector</div>
          <div>Information Technology Sector</div>
          <div>Media & Entertainment Sector</div>
        </div>
        <div>
          <div>Travel & Hospitality Sector</div>
          <div>Education / eLearning Sector</div>
          <div>Logistic & Distribution Sector</div>
        </div>
      </div>
    </>
  );
};

export default Sectors;
