import React, { useState } from "react";
import Header from "../components/Header/Header";
import { useNavigate } from "react-router-dom";

const HeaderContainer = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleDialog = () => {
    setOpenDialog(true);
    setShowMenu(false);
  };

  const history = useNavigate();

  const handleServices = () => {
    history("/services");
    setShowMenu(false);
  };

  const handleLogo = () => {
    history("/");
  };

  const handleAbout = () => {
    history("/about_us");
    setShowMenu(false);
  };

  const handleMenu = () => {
    setShowMenu(true);
  };

  const handleEP = () => {
    // history("/adep");
  };

  return (
    <>
      <Header
        openDialog={openDialog}
        handleDialog={handleDialog}
        setOpenDialog={setOpenDialog}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        handleServices={handleServices}
        handleLogo={handleLogo}
        handleAbout={handleAbout}
        handleMenu={handleMenu}
        handleEP={handleEP}
      />
    </>
  );
};

export default HeaderContainer;
