import React from "react";
import HeaderContainer from "./HeaderContainer";
import Aim from "../components/Aim/Aim";
import Counts from "../components/Counts/Counts";
import Footer from "../components/Footer/Footer";
import Category from "../components/Category/Category";
import Map from "../components/MapApi/Map";

const HomeContainer = () => {
  return (
    <>
      <div style={{ backgroundColor: "#000" }}>
        <div>
          <HeaderContainer />
        </div>
        <div style={{ padding: "72px 0px 0px 0px" }}>
          <Aim />
          <Counts />
          <Category />
          <Map />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default HomeContainer;
