// import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
// import { useMemo } from "react";
import "./Map.css";
// import { Hidden } from "@mui/material";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import EmailIcon from "@mui/icons-material/Email";
// import PhoneIcon from "@mui/icons-material/Phone";

const Map = () => {
  //   const { isLoaded } = useLoadScript({
  //     googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  //   });
  //   const center = useMemo(() => ({ lat: 26.2124, lng: 78.1772 }), []);

  return (
    <div className="mapContainer">
      <div>
        {/* {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={10}
        >
          <Marker position={center} />
        </GoogleMap>
      )} */}
      </div>
      <div className="map-container1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d229085.78864706444!2d78.02608783441862!3d26.214061912373356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3976c5d1792291fb%3A0xff4fb56d65bc3adf!2sGwalior%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1693038295599!5m2!1sen!2sin"
          className="map-container"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="Location"
        ></iframe>
      </div>
    </div>
  );
};

export default Map;
