import React from "react";
import "./Aim.css";

// import Video from "../../../public/assets/animatedvideo.mp4";
const Aim = () => {
  return (
    <>
      <div className="aimContainer">
        <div className="aimTxt1">
          Introducing <span className="aimMainTxt">DOERDEVS</span> - your
          destination for flawlessly executed software solutions. With precision
          as our compass, we create innovative applications that hit the mark,
          every project, every time.
        </div>
        <div className="aimTxt2">
          Unleash unparalleled software development with our on-demand experts.
          Elevate projects with the industry's best minds, ensuring innovation,
          efficiency, and excellence at every turn.
        </div>

        <div>
          <video
            autoPlay
            loop
            // controls
            src="/assets/animatedVideo.mp4"
            className="aimVideoCss"
          />
        </div>
      </div>
    </>
  );
};

export default Aim;
