import React, { useState, useEffect } from "react";
import "./Counts.css";

const Counts = () => {
  const [count, setCount] = useState(1);
  const [displayCount, setDisplayCount] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount === 100) {
          clearInterval(intervalId);
          return 100;
        }
        return prevCount + 1;
      });
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (count >= 100) {
      setDisplayCount(`${count}+`);
    } else {
      setDisplayCount(count);
    }
  }, [count]);
  return (
    <>
      <div className="countContainer">
        <div className="countDiv">
          <div className="countsText1">1+</div>
          <div className="countsText2">Total Years Experience</div>
        </div>
        <div className="countDiv">
          <div className="countsText1">{displayCount}</div>
          <div className="countsText2">LinkedIn Followers</div>
        </div>
        <div className="countDiv">
          <div className="countsText1">3+</div>
          <div className="countsText2">Client Served</div>
        </div>
        <div className="countDiv">
          <div className="countsText1">4+</div>
          <div className="countsText2">Experienced Developers</div>
        </div>
      </div>
    </>
  );
};

export default Counts;
