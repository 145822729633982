import React from "react";
import "./Services2.css";

const Services2 = () => {
  return (
    <>
      <div className="services2-container">
        <div className="services1-container-mainPart">
          <div className="services1-Left">
            <div> Custom Software Development</div>
            <div>
              Our team of skilled developers excels in creating bespoke software
              applications that perfectly align with your unique business
              requirements. From concept to deployment, we ensure that your
              software not only meets but exceeds your expectations.
            </div>
          </div>
          <div className="services1-right">
            <img alt="images" src="/assets/development.jpg" className="servicesImages" />
          </div>
        </div>

        <div className="services1-container-mainPart">
          <div className="services1-right">
            <img alt="images" src="/assets/web.png" className="servicesImages" />
          </div>
          <div className="services1-Left">
            <div>Web Application Development</div>
            <div>
              Leverage the power of the web with our dynamic and responsive web
              application development services. We create user-centric
              interfaces and seamless experiences that engage and captivate your
              audience.
            </div>
          </div>
        </div>

        <div className="services1-container-mainPart">
          <div className="services1-Left">
            <div>Mobile App Development</div>
            <div>
              In a mobile-first world, we craft innovative and intuitive mobile
              applications for iOS and Android platforms. Our apps are designed
              for performance, security, and user satisfaction.
            </div>
          </div>
          <div className="services1-right">
            <img alt="images" src="/assets/app.png" className="servicesImages" />
          </div>
        </div>

        <div className="services1-container-mainPart">
          <div className="services1-right">
            <img alt="images" src="/assets/solution.png" className="servicesImages" />
          </div>
          <div className="services1-Left">
            <div>Enterprise Software Solutions</div>
            <div>
              Streamline your operations with our enterprise software solutions.
              We create scalable, efficient, and secure systems that optimize
              your business processes, enhancing productivity and agility.
            </div>
          </div>
        </div>

        <div className="services1-container-mainPart">
          <div className="services1-Left">
            <div>UI/UX Design</div>
            <div>
              Our design team specializes in creating visually stunning and
              user-friendly interfaces. We prioritize user experience to ensure
              that your software not only looks great but is also intuitive and
              easy to use.
            </div>
          </div>
          <div className="services1-right">
            <img alt="images" src="/assets/ui.png" className="servicesImages" />
          </div>
        </div>

        <div className="services1-container-mainPart">
          <div className="services1-right">
            <img alt="images" src="/assets/software.png" className="servicesImages" />
          </div>
          <div className="services1-Left">
            <div>Software Consulting</div>
            <div>
              From ideation to strategy, our consultants offer expert guidance
              at every stage of your software project. We analyze your business
              needs, provide technology recommendations, and chart a roadmap for
              success.
            </div>
          </div>
        </div>

        <div className="services1-container-mainPart">
          <div className="services1-Left">
            <div>Quality Assurance and Testing</div>
            <div>
              Our rigorous testing processes guarantee the reliability,
              functionality, and security of your software. We leave no stone
              unturned to deliver a seamless user experience.
            </div>
          </div>
          <div className="services1-right">
            <img alt="images" src="/assets/quality.png" className="servicesImages" />
          </div>
        </div>

        <div className="services1-container-mainPart">
          <div className="services1-right">
            <img alt="images" src="/assets/maintenence.png" className="servicesImages" />
          </div>
          <div className="services1-Left">
            <div>Maintenance and Support</div>
            <div>
              Our commitment doesn't end with deployment. We offer ongoing
              maintenance and support services to ensure your software remains
              up-to-date, secure, and optimized for peak performance.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services2;
