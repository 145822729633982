import React from "react";
import Services1 from "../components/Services/Services1/Services1";
import HeaderContainer from "./HeaderContainer";
import Services2 from "../components/Services/Services2/Services2";
import Footer from "../components/Footer/Footer";
import Services3 from "../components/Services/Services3/Services3";
const ServicesContainer = () => {
  return (
    <>
      <div style={{ backgroundColor: "#000" }}>
        <HeaderContainer />
        <div style={{ padding: "58px 0px 0px 0px" }}>
          <Services1 />
          <Services2 />
          <Services3 />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ServicesContainer;
