import React from "react";
import "./Services3.css";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
const Services3 = () => {
  return (
    <>
      <div className="services3-container">
        <div className="services3-div1">
          <img alt="images" src="/assets/sdlc.png" className="sdlc" />
        </div>
        <div className="services3-div2">
          <div className="services3-txt1">
            Our efficient operational process.
          </div>
          <div className="services3-txt2">
            Our streamlined workflow adheres to the industry-approved Software
            Development Life Cycle (SDLC) model, enabling us to deliver precise
            products on time.
          </div>
          <div className="services3-txt3">
            <div>
              <div>
                <DoubleArrowIcon />
              </div>
              <div> Discovery Discussion</div>
            </div>
            <div>
              <div>
                <DoubleArrowIcon />
              </div>
              <div>Tailored Strategy</div>
            </div>
            <div>
              <div>
                <DoubleArrowIcon />
              </div>
              <div>Creative Design</div>
            </div>
            <div>
              <div>
                <DoubleArrowIcon />
              </div>
              <div>Agile Development</div>
            </div>
            <div>
              <div>
                <DoubleArrowIcon />
              </div>
              <div>Robust Testing</div>
            </div>
            <div>
              <div>
                <DoubleArrowIcon />
              </div>
              <div>Effortless Launch</div>
            </div>
            <div>
              <div>
                <DoubleArrowIcon />
              </div>
              <div> Continuous Innovation</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services3;
