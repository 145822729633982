import React from "react";
import About1 from "../components/AboutUs/About1";
import HeaderContainer from "./HeaderContainer";
import Footer from "../components/Footer/Footer";

const AboutUsContainer = () => {
  return (
    <>
      <div style={{ backgroundColor: "#000" }}>
        <HeaderContainer />
        <div style={{ padding: "72px 0px 0px 0px" }}>
          <About1 />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AboutUsContainer;
