import React from "react";
import "./Category.css";
import { Hidden } from "@mui/material";

const Category = () => {
  return (
    <>
      <div className="categoryContainer">
        <div className="categoryTxt1">Expert in every aspect of</div>
        <div className="categoryTxt2">Product Life-Cycle</div>

        <div className="categoryBox1">
          <div className="categoryBox1-div1">
            <div>E-commerce Development</div>
            <div>
              Whichever front-end technologies exists in real world, We've got
              xepertise in each to make your product innovative.
            </div>
          </div>
          <Hidden smDown>
            <div className="categoryBox1-div2">
              <div>
                <div>Branding</div>
                <div style={{ color: "#fff" }}>
                  - Brand Strategy & Experience
                </div>
                <div style={{ color: "#fff" }}>- Go-To-Market Branding</div>
                <div style={{ color: "#fff" }}>- Digital Strategy</div>
                <div style={{ color: "#fff" }}>- UI UX Designs</div>
              </div>
              <div>
                <div>Discovery</div>
                <div style={{ color: "#fff" }}>
                  - Competitive Benchmarking
                </div>
                <div style={{ color: "#fff" }}>
                  - Customer research and data analysis
                </div>
                <div style={{ color: "#fff" }}>
                  - User flows and customer journey
                </div>
                <div style={{ color: "#fff" }}>- Wireframes and testing</div>
              </div>
            </div>
          </Hidden>
        </div>

        <div className="categoryBox2">
          <div className="categoryBox2-div1">
            <div>Our Approach</div>
            <div>
              We place the user at the center of our design philosophy, creating
              solutions that are intuitive, user-friendly, and visually
              appealing. Our agile approach ensures flexibility, adaptability,
              and constant collaboration. We iterate quickly to incorporate
              feedback and stay aligned with project goals. We leverage the
              latest tools and technologies to build solutions that are
              future-proof, scalable, and optimized for performance.
            </div>
          </div>

          <div className="categoryBox2-div">
            <div className="categoryBox2-div2">
              <div>React.js / Next.js</div>
              <div>
                We specialize in React.js. Crafting dynamic, responsive, and
                modern web applications.
              </div>
            </div>
            <div className="categoryBox2-div2">
              <div>Node.js / Nest.js</div>
              <div>
                Backend scalability is the most priority. We got you covered
                with best archietectures on Serverless or Server-based.
              </div>
            </div>
            <div className="categoryBox2-div2">
              <div>App / Native Development</div>
              <div>
                We are also specialised on react native based technologies &
                delivered complex platforms.
              </div>
            </div>
            <div className="categoryBox2-div2">
              <div>Business Automation</div>
              <div>
                Backend scalability is the most priority. We got you covered
                with best archietectures on Serverless or Server-based.
              </div>
            </div>
          </div>
        </div>

        <div className="categoryBox3">
          <div className="categoryBox3-div1">
            <div>CTO as a service</div>
            <div>
              Access expert Chief Technology Officer guidance remotely. Drive
              innovation, tech strategy, and execution for your business
              success.
            </div>
          </div>
          <div className="categoryBox3-div1">
            <div>Business Automation</div>
            <div>
              Backend scalability is the most priority. We got you covered with
              best archietectures on Serverless or Server-based.
            </div>
          </div>
          <div className="categoryBox3-div2"></div>
        </div>

        <div className="categoryTxt5">
          <div className="categoryTxt3">" Innovating Solutions,</div>
          <div className="categoryTxt4">Empowering Progress. "</div>
        </div>

        <div className="categoryTxt6">
          Whether you're a Fortune 500 or a startup in stealth mode - we give
          our clients the creative, technical and business talent they need to
          succeed.
        </div>
      </div>
    </>
  );
};

export default Category;
