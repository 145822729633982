import React from "react";
import "./Services1.css";

const Services1 = () => {
  return (
    <>
      <div className="services1-container">
        <div className="services1-div1">
          Unleashing Software Excellence: Empowering Digital Innovation and
          Transformation
        </div>

        <div className="services1-div2">
          At DOERDEVS, we are dedicated to transforming your vision into
          reality through cutting-edge software solutions. With our exceptional
          services and expertise, we provide tailor-made software development
          solutions that empower businesses to thrive in the digital age.
        </div>

        <div className="serviceVideo">
          <video
            autoPlay
            loop
            src="/assets/animation.mp4"
            className="servicesVideoCss1"
          />
        </div>
      </div>
    </>
  );
};

export default Services1;
