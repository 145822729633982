import React from "react";
import "./About1.css";

const About1 = () => {
  return (
    <>
      <div className="about1-container">
        <div className="about1-container-div">
          <div>Empowering Through Innovation Since 2023</div>
          <div>
            At <span className="aimMainTxt"> DOERDEVS</span> , we stand as a
            testament to the power of innovation in the digital era. Founded in
            2023, our journey is a reflection of our commitment to shaping the
            future through cutting-edge software solutions.
          </div>
        </div>

        <div
          style={{ textAlign: "center", marginBottom: "64px" }}
          className="about1-container-div-video"
        >
          <video
            autoPlay
            loop
            src="/assets/AppDevelopment.mp4"
            height={"auto"}
            width={"70%"}
          />
        </div>

        <div className="about1-container-div">
          <div>Our Founding Vision</div>
          <div>
            Our inception marked the beginning of a visionary endeavor. With a
            strong belief in the potential of technology to drive positive
            change, we set out to create a software company that goes beyond
            conventions, challenges the status quo, and paves the way for
            groundbreaking advancements.
          </div>
        </div>

        <div className="about1-container-div">
          <div>Join Us in Shaping Tomorrow</div>
          <div>
            As we embark on this exciting journey, we invite you to be a part of
            our story. Together, let's push the boundaries of what's possible,
            innovate relentlessly, and forge a future where software is not just
            a tool, but a catalyst for transformation.
          </div>
          <div>
            Connect with DOERDEVS to explore how our passion for technology and
            innovation can drive your success.
          </div>
        </div>
      </div>
    </>
  );
};

export default About1;
