import React from "react";
import "./Header.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { Hidden, Paper } from "@mui/material";
import Sectors from "./Sectors";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const Header = (props) => {
  const {
    openDialog,
    setOpenDialog,
    handleDialog,
    setShowMenu,
    showMenu,
    handleMenu,
    handleAbout,
    handleServices,
    handleLogo,
    handleEP,
  } = props;

  return (
    <>
      <div className="headerContainer">
        <div onClick={handleLogo} className="headerLeft">
          <div className="logoText">DOER</div>
          <img alt="images" src="/assets/logo.png" className="mainLogo" />
          <div className="logoText">DEVS</div>
        </div>

        <div className="headerRight">
          <Hidden smDown>
            <div className="headerRightFirsttdiv">
              <div className="headerRight-div" onClick={handleServices}>
                Services
              </div>

              <div className="headerRight-div" onClick={handleAbout}>
                About Us
              </div>

              <div className="headerRight-div">
                <div onClick={handleDialog}>Sectors</div>
                {openDialog ? (
                  <ExpandLessIcon onClick={() => setOpenDialog(false)} />
                ) : (
                  <ExpandMoreIcon onClick={handleDialog} />
                )}
              </div>

              <div onClick={handleEP} className="headerRight-div">
                Training Programme
                <div
                  style={{
                    position: "absolute",
                    top: "-10px",
                    fontSize: "8px",
                    right: "10px",
                    color: "#27ae60",
                  }}
                >
                  Exclusive
                </div>
              </div>
            </div>

            <div className="headerRight-div-div">
              <a href="mailto:info@doerdevs.com?subject = Feedback&body = Message">
                Let's Talk
              </a>
              <HandshakeIcon />
            </div>
          </Hidden>
          <Hidden smUp>
            <div className={!showMenu && "menuAnima"}>
              {!showMenu && <MenuIcon onClick={handleMenu} htmlColor="#fff" />}
            </div>
          </Hidden>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {showMenu && (
          <Paper
            sx={{
              borderRadius: "16px",
              position: "fixed",
              zIndex: 999,
            }}
            elevation={3}
            className="menuDialog"
          >
            <div className="dialogClose">
              <CloseIcon
                onClick={() => setShowMenu(false)}
                fontSize="medium"
                htmlColor="#fff"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="alignMenuItems">
              <div className="headerRight-div" onClick={handleServices}>
                Services
              </div>
              <div className="headerRight-div" onClick={handleAbout}>
                About Us
              </div>
              <div className="headerRight-div" onClick={handleDialog}>
                Sectors
              </div>
            </div>
          </Paper>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {openDialog && (
          <Paper
            sx={{ borderRadius: "16px", position: "fixed", top: "66px" }}
            elevation={3}
            className="headerDialog"
          >
            <div className="dialogClose">
              <CloseIcon
                onClick={() => setOpenDialog(false)}
                fontSize="medium"
                style={{ cursor: "pointer" }}
              />
            </div>
            <Sectors />
          </Paper>
        )}
      </div>
    </>
  );
};

export default Header;
